import React ,{ useEffect } from "react";
import {
    Container,
    Row,
    Col,
} from "reactstrap";
// reactstrap components
import { scroller } from "react-scroll";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";


// index sections
import SectionLogin from "views/index-sections/SectionLogin.js";

function ReglamentoInterno() {

    useEffect( () => {
     scroller.scrollTo('reglamento-interno', {
        smooth: true,
        offset: -70,
        duration: 500
      })},[])

    return (
        <div>
            <IndexNavbar />
            <IndexHeader />
            <div className="main">
                <div className="section text-center">
                    <Container  id="reglamento-interno">
                        <Row>
                            <Col style={{ marginBottom: '100px' }}>
                                <h2 className="title">Reglamento Interno</h2>
                                <h3 style={{ textAlign: 'left', paddingBottom: '15px' }}></h3>
                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Normas de convivencia</h4>
                                <h5 align="left" font-weight="500" style={{ color: '#333333' }}>
                                    Complejo casas de campo es esencialmente familiar, por lo tanto, los huéspedes /o visitantes invitados deben respetar las normas de convivencia y buenas costumbres, debiendo abstenerse de realizar actos que perturben o incomoden a los otros huéspedes alojados en el complejo.
                                </h5>
                                <ul>
                                    <li><h5 align="left">Le solicitamos conservar y mantener la CABAÑA en perfectas condiciones durante su estadía. El uso racional de las instalaciones implica cooperar en el mantenimiento y la limpieza de la cabaña y los espacios circundantes.</h5></li>
                                    <li><h5 align="left">Recomendamos que a su llegada revise la cabaña ya que al momento de su retiro será revisado y en caso de faltantes o daños deberán ser cubiertos por el huésped por otro producto igual o en su defecto se cobrará su precio.</h5></li>
                                    <li><h5 align="left">Cada cabaña cuenta con asador, en él se encuentra una parrilla y un juego de pala y tizador.</h5></li>
                                    <li><h5 align="left">Siempre controle el fuego del asador y luego de su uso verifique quede bien apagado, Córdoba es zona de riesgo alto de incendio por lo cual debe tener esas precauciones.</h5></li>
                                    <li><h5 align="left">Cualquier deterioro o pérdida del inventario; Toallas, ropa de cama, parte de la vajilla, llave o controles remotos causado durante su estadía, deberá ser repuesto o cancelado en recepción.</h5></li>
                                    <li><h5 align="left">Dar aviso de inmediato a la administración de cualquier desperfecto, rotura o anormalidad que afecte a La cabaña que ocupe.</h5></li>
                                    <li><h5 align="left">No introducir al interior de la cabaña elementos inflamables o peligrosos para las personas. Por ende, está prohibido fumar dentro de la cabaña.</h5></li>
                                    <li><h5 align="left">Con el fin de hacer uso racional de la energía y por motivos de seguridad, tormentas y/o bajas de tensión <b>les solicitamos que cuando los huéspedes se ausenten de la cabaña, los equipos tales como ventiladores, aire acondicionado, y cocina sean apagados. La administración se reserva el derecho de ingresar a la cabaña a apagar dichos equipos si esta norma no se cumple.</b> </h5></li>
                                    <li><h5 align="left">Solicitamos también dejar apagadas todas las luces cuando no se encuentra ningún huésped en la cabaña.</h5></li>
                                    <li><h5 align="left">Recuerde no dejar corriendo agua innecesariamente en duchas, lavatorios y piletas.</h5></li>
                                    <li><h5 align="left">Respetar la flora y fauna, no molestar los pájaros y no cortar plantas o flores.</h5></li>
                                    <li><h5 align="left">Evitar la contaminación sonora.</h5></li>
                                    <li><h5 align="left"><b>No está permitido el uso de equipos de música y/o cualquier otro tipo de equipamiento que puedan incomodar a otros huéspedes. No está permitida la utilización de equipos de música en el sector de pileta.</b></h5></li>
                                    <li><h5 align="left"><b>La plaza de juegos infantiles es para uso exclusivo de niños siempre supervisados por un adulto.</b></h5></li>
                                    <li><h5 align="left">No arrojar piedras y cuidar que los niños tampoco lo hagan.</h5></li>
                                    <li><h5 align="left">No circular dentro del predio con ciclomotores, cuatriciclos, motocicletas solo puede hacerlo para dirigirse a su cabaña.</h5></li>
                                    <li><h5 align="left"><b>No está permitido llevar los sillones de la piscina a las cabañas.</b></h5></li>
                                    <li><h5 align="left">No hospedamos mascotas.</h5></li>
                                </ul>

                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Check in y Check Out</h4>
                                <h5 align="left">El check in es a partir de las 14hs. <br />
                                    El check out es hasta las 10hs, pasado dicho horario se cobrará una multa equivalente a un día más de alojamiento del cual no podrá hacer uso. En caso de considerar horario de salida posterior al establecido para el check out, notificarlo con 72 horas de anticipación para verificar la posibilidad de hacerlo.
                                </h5>


                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Horarios de silencio</h4>
                                <h5 align="left">Los pasajeros deberán respetar los horarios de silencio establecidos:<br />
                                    •	De 22:00 hrs. a 09.00 hrs.<br />
                                    •	De 14:30 hrs. a 16:30 hrs.

                                </h5>


                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Desayuno</h4>
                                <h5 align="left">Sin servicio de desayuno (el complejo no cuenta con este servicio)
                                </h5>

                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Ropa blanca y elementos de higiene</h4>
                                <h5 align="left">La administración prestará a los usuarios los siguientes servicios: Ropa de cama, toallas y toallones (si fue contratado) y vajilla. <br />
Se entregará sólo al ingreso papel higiénico, jabón de tocador, detergente de vajillas, esponja y paño de cocina.
El cambio de toallas y toallones se efectúa cada 3 días. Les dejamos el total del recambio y solicitamos por favor entregar la misma cantidad, aunque estén sin uso. Las toallas y toallones sólo son para uso interno en la cabaña, no está permitido el uso de los mismos en el sector de pileta. <br />
En estadías de 10 días o más se entregará a mitad de la estadía los juegos de sábanas correspondientes para su recambio.<br />
<b>El servicio de mucama no está incluido en la tarifa y si desea contar con él debe solicitarlo en la administración.</b>

                                </h5>

                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Internet Wifi</h4>
                                <h5 align="left">El servicio de Internet Wifi es sin cargo. Solicitar usuario y contraseña al ingreso. Al estar en una zona rural la velocidad de internet no es igual que en la ciudad. La cobertura del wifi a veces se ve afectada por la vegetación.<br /> <br />
Los servicios de Energía eléctrica, nivel de señal de telefonía móvil y Wifi son responsabilidad exclusiva de sus respectivos prestadores no participando este complejo de responsabilidad alguna en dichas prestaciones.


                                </h5>

                                
                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Aire Acondicionado</h4>
                                <h5 align="left">El aire acondicionado es opcional y no está incluido en la tarifa, el servicio deberá solicitarlo en administración y su costo por dia es $ 800<br />
                               <b>(Está prohibido la manipulación del mismo y su encendido sin autorización será multado con el valor de un día de alojamiento y deberá abonar los daños posiblemente efectuados al artefacto)</b>
                                </h5>

                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Piscina</h4>
                                <h5 align="left">Abierta de 10:00 hrs. a 20:00 hs. Fuera de ese horario se realiza el mantenimiento de la misma, por lo cual no se permite su uso.<br />
La piscina es de uso exclusivo para los huéspedes alojados en el complejo.<br />
Para el uso de la piscina es absolutamente obligatorio que los menores de 13 años se encuentren acompañados de una persona adulta responsable. No contamos con guardavida.<br />
Se encuentra prohibido ingresar al perímetro de la piscina con alimentos y/o bebidas.<br />

                                </h5>

                                <h4 style={{ textAlign: 'left', paddingBottom: '10px' }}>Pago, cancelación de estadía o salida anticipada</h4>
                                <h5 align="left">Las reservas se concretan con el depósito de garantía (seña) del 30% del valor total de la estadía, no significando esto días de alojamiento. El pago de la estadía por el período acordado se realizará al momento del ingreso junto con el registro de los huéspedes y la aceptación del presente reglamento interno.<br />
Si por algún motivo desea incrementar la cantidad de huéspedes alojados deberá informarlo con anterioridad, expresa autorización en la administración abonando la diferencia correspondiente.<br />
En caso de que la cancelación de la reserva se efectuara con hasta 30 días de anticipación al día de ingreso, se reintegrará el total del depósito efectuado, descontando gastos bancarios y administrativos.<br />
En caso de querer recibir visitas, las mismas podrán ingresar en horario diurno y solicitando previamente la autorización al administrador. Las visitas no podrán hacer uso de la piscina.<br />
Complejo casas de campo no se responsabiliza por la pérdida de objetos personales, dinero u otros valores que nuestros huéspedes guardaran en sus habitaciones.<br />
Les solicitamos por favor considerar los puntos descriptos. Si usted colabora respetando los mismos posibilitará una mejor atención.<br />
Sus sugerencias o comentarios serán Bienvenidos.<br />
Por favor recurran a nosotros para lo que necesiten.
                                </h5>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <SectionLogin />
                <DemoFooter />
            </div>
        </div>
    );
}

export default ReglamentoInterno;
