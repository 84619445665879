
/*eslint-disable*/
import React from "react";
import { Link } from 'react-router-dom';

// reactstrap components
import { Row, Container ,Col } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <Col sm="12" md="6">
          <nav className="footer-nav">
            <ul style={{display: 'flex',alignItems:'center'}}>
              <li>
              <img alt="Casas de Campo Logo" src={require("assets/img/casas-campo/logo-color.svg").default} height="60" width="60" />
              </li>
              <li>
                <p>Complejo Casas de Campo . Todos los derechos reservados . <Link to="/reglamento" style={{textTransform:'none',fontWeight:'400',fontSize: '15px',lineHeight: '1.5em',marginBottom: '5px',textAlign: 'left',
                  fontFamily: 'Roboto'}}>Reglamento Interno</Link></p>
              </li>
            </ul>
          </nav>
          </Col>
          <Col className="logo-tawa" sm="12" md="6" >
       <a  href="https://somostawa.com.ar/" target="_blank" style={{display: 'flex',alignItems:'center', color:'#66615b'}}>
        <p  style={{marginRight: '10px',paddingTop:'6px'}}>Desarrollado por</p>
        <img height="18px" src={require("assets/img/casas-campo/logo-tawa.svg").default}  alt="Logo Tawa" />
        </a>
        </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
