
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReglamentoInterno from "views/reglamento.js";
// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";


ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/reglamento" element={<ReglamentoInterno />} />
      <Route path="/" element={<Index/>} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
