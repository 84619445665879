import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
// reactstrap components

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import GalleryComponent  from "views/index-sections/gallery.js";
import MediaCard  from "views/index-sections/cardGallery.js";
import MediaCard2  from "views/index-sections/cardGallery2.js";
import MediaCard3  from "views/index-sections/cardGallery3.js";
import MediaCard4  from "views/index-sections/cardGallery4.js";

// index sections
import SectionLogin from "views/index-sections/SectionLogin.js";

function Index() {
  return (
    <div>
      <IndexNavbar />
      <IndexHeader />
      <div className="main">
      <div className="section text-center">
          <Container id="nuestro-complejo"> 
            <Row>
              <Col style={{ marginBottom: '100px' }}>
                <h2 className="title">Nuestro complejo</h2>
                <h5 align="left" font-weight="500" style={{ color: '#333333' }}>
                Nuestro Complejo Casas de Campo es un lugar de las sierras, ideal para desconectarse de la ciudad, del ir y venir de los autos, de los ruidos, de la tele… Te verás rodeado de pura naturaleza, monte autóctono prolijamente cuidado para que puedas recorrer a gusto, algarrobos, chañares, tuscas… son algunas de las especies que puedes conocer, sorprendiéndote además, de lo que constituye un “verdadero ecosistema natural”: Nuestro reservorio de agua con peces y plantas acuáticas, todo esto, enmarcado por el sonido de una infinita variedad de aves que habita en este maravilloso lugar.
                <br /><br />En fin, te invitamos a hospedarte en “Casas de Campo” nuestras cabañas, realizadas en piedra y madera, con amplios ventanales te brindan la posibilidad de descansar, desenchufarte, y por sobre todo, de hacer “algo distinto”…
                <br /><br /><span>Seguro… vas a querer volver…</span>
                </h5>
              </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <Col md="10" style={{ paddingLeft: '40px', paddingRight: '40px', marginBottom:'48px' }}>                
                <div className="info">
                <Row>
                  <Col md="2" style={{ alignContent: 'center', alignItems: 'center', display:'flex',justifyContent: 'center' }}>
                  <div className="icon icon-info">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  </Col>
                  <Col md="10">
                  <div className="description">
                    <h4 className="info-title" style={{marginBottom: '15px',marginTop: '0',fontWeight: '700' }}>Descanso y Tranquilidad</h4>
                    <p style={{ color: '#333333' }}>
                     · Un extenso “Parque Nativo” cuidado de tal forma que le permitirá recorrer y disfrutar de la mejor manera.<br />
                     · Piscina de 17 mts de largo con iluminación nocturna, diseñada en función de niños y adultos. Su ubicación permite aprovechar, al caer la tarde, hasta los últimos rayos de sol.
                    </p>
                  </div>
                  </Col>
                </Row>
                </div>                
              </Col>
              <Col md="10" style={{ paddingLeft: '40px', paddingRight: '40px', marginBottom:'48px' }}>
                <div className="info">
                <Row>
                  <Col md="2" style={{ alignContent: 'center', alignItems: 'center', display:'flex',justifyContent: 'center' }}>
                  <div className="icon icon-info">
                    <i className="nc-icon nc-favourite-28" />
                  </div>
                  </Col>
                  <Col md="10">
                  <div className="description">
                    <h4 className="info-title" style={{marginBottom: '15px',marginTop: '0',fontWeight: '700' }}>Confort</h4>
                    <p style={{ color: '#333333' }}>
                    Salón de usos múltiples: este espacio totalmente vidriado, ha sido pensado para que quienes se hospedan en nuestras cabañas, tengan un sitio de encuentro y de entretenimiento. En este lugar se encuentra ubicado el televisor, a disposición de quienes lo soliciten, además cuenta con WiFi.
                    </p>
                  </div>
                  </Col>
                </Row> 
                </div>
              </Col>
              <Col md="10" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                <div className="info">
                <Row>
                  <Col md="2" style={{ alignContent: 'center', alignItems: 'center', display:'flex',justifyContent: 'center' }}>
                  <div className="icon icon-info">
                    <i className="nc-icon nc-satisfied" />
                  </div>
                  </Col>
                  <Col md="10">
                  <div className="description">
                    <h4 className="info-title" style={{marginBottom: '15px',marginTop: '0',fontWeight: '700' }}>Relax Garantizado</h4>
                    <p style={{ color: '#333333' }}>
                    En el predio, algunos juegos para niños, representan siempre una sana distracción.<br />
                    Servicios Opcionales (costo adicional):<br />
                    · Ropa blanca: juego de sábanas, toallas y toallones<br />
                    · Servicio de limpieza<br />
                    · Aire acondicionado
                    </p>
                  </div>
                  </Col>
                </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <GalleryComponent>
        </GalleryComponent>
        <Container id="cabañas" className="section">
        <h2 className="title" style={{ textAlign: 'center' }}>Nuestras cabañas</h2>
          <Row>
            <Col md="6" style={{marginTop: '48px'}}>
              <MediaCard />
            </Col>
            <Col md="6" style={{marginTop: '48px'}}>
              <MediaCard2 />
            </Col>
            <Col md="6" style={{marginTop: '48px'}}>
              <MediaCard3 />
            </Col>
            <Col md="6" style={{marginTop: '48px'}}>
              <MediaCard4 />
            </Col>
          </Row>
        </Container>
        <SectionLogin />
        <DemoFooter />
      </div>
    </div>
  );
}

export default Index;
