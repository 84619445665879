
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import {useNavigate} from 'react-router-dom';
import logoBlanco from "./logo-blanco.svg"
import logoColor from "./logo-color.svg"
// import { Link, animateScroll as scroll } from "react-scroll";
// reactstrap components
import { scroller } from "react-scroll";

import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [imgRoute, setImgRoute] = React.useState(logoBlanco);
  const navigate = useNavigate();
  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
        setImgRoute(logoColor);
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
        setImgRoute(logoBlanco);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
               onClick={() => scroller.scrollTo('header', {
                smooth: true,
                offset: -70,
                duration: 500
              })} 
              href="#"
            title="Logo Complejo Casas de Campo"
          >
            <img  alt="Casas de Campo Logo" src={imgRoute} height="90" width="90" />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            {/* <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/CreativeTim?ref=creativetim"
                target="_blank"
                title="Like us on Facebook"
              >
                <i className="fa bed-emptye" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Booking</p>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
               href="#"
               onClick={() => {
                navigate('/')
                 scroller.scrollTo('nuestro-complejo', {
                smooth: true,
                offset: -70,
                duration: 500
              })
              }}>
                 Nuestro Complejo
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
               href="#"
               onClick={() =>{
                navigate('/')
                  scroller.scrollTo('cabañas', {
                smooth: true,
                offset: -70,
                duration: 500
              })}}>
                 Las Cabañas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                onClick={() => {
                  navigate('/')
                  scroller.scrollTo('como-encontrarnos', {
                 smooth: true,
                 offset: -70,
                 duration: 500
               })}}>
                 Como Encontrarnos
              </NavLink>
            </NavItem>
            <NavItem>
              <Button
                className="btn-round"
                color="danger"
                href="https://api.whatsapp.com/send?phone=5493515201394&text=Hola%2C%20que%20tal%3F%20Tengo%20una%20consulta%20acerca%20de%20las%20caba%C3%B1as..."
                target="_blank"
              >
              Contactanos
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
