import React , { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Carousel from 'react-material-ui-carousel';
import Carousel1 , { Modal, ModalGateway } from "react-images";
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

export const photos = [
  {
    src: `${require('assets/img/Cabañas/cabaña 1/1.webp').default}`,
    width: 4,
    height: 3
  },
  {
    src: `${require('assets/img/Cabañas/cabaña 1/2.webp').default}`,
    width: 1,
    height: 1
  },
  {
    src: `${require('assets/img/Cabañas/cabaña 1/3.webp').default}`,
    width: 3,
    height: 4
  },
  {
    src: `${require('assets/img/Cabañas/cabaña 1/4.webp').default}`,
    width: 4,
    height: 3
  },
  {
    src: `${require('assets/img/Cabañas/cabaña 1/5.webp').default}`,
    width: 1,
    height: 1
  },
  {
    src: `${require('assets/img/Cabañas/cabaña 1/6.webp').default}`,
    width: 3,
    height: 4
  },
  {
    src: `${require('assets/img/Cabañas/cabaña 1/7.webp').default}`,
    width: 4,
    height: 3
  },
  {
    src: `${require('assets/img/Cabañas/cabaña 1/8.webp').default}`,
    width: 1,
    height: 1
  },
  {
    src: `${require('assets/img/Cabañas/cabaña 1/9.webp').default}`,
    width: 3,
    height: 4
  }
];

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 400,
      margin:'auto',
      boxShadow: "0 0 50px rgba(0,0,0,0.1)",
      minHeight: 475,
      borderRadius: 6
    },
    media: {
      height: 200,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));
  
  export default function MediaCard() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const classes = useStyles();

    const openLightbox = useCallback((event) => {
        setCurrentImage(0);
        setViewerIsOpen(true);
      }, []);
    
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };
    return (
      <Card className={classes.root}>
        <CardActionArea  onClick={openLightbox}>
        <Carousel
                    className="SecondExample"
                    autoPlay={false}
                    animation={true}
                    indicators={true}
                    timeout={400}
                    navButtonsAlwaysVisible={false}
                    navButtonsAlwaysInvisible={false}

                >
         <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/1.webp').default}
          title="Contemplative Reptile"
        />
         <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/2.webp').default}
          title="Contemplative Reptile"
        />
         <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/3.webp').default}
          title="Contemplative Reptile"
        />
        <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/4.webp').default}
          title="Contemplative Reptile"
        />
         <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/5.webp').default}
          title="Contemplative Reptile"
        />
         <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/6.webp').default}
          title="Contemplative Reptile"
        />
        <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/7.webp').default}
          title="Contemplative Reptile"
        />
         <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/8.webp').default}
          title="Contemplative Reptile"
        />
         <CardMedia
          className={classes.media}
          image={require('assets/img/Cabañas/cabaña 1/9.webp').default}
          title="Contemplative Reptile"
        />
          </Carousel>
          </CardActionArea>
          <CardContent style={{ paddingBottom:'0px' }}>
            <Typography gutterBottom variant="h5" component="h2">
              Cabaña individual amplia para 6 personas
            </Typography>
            <Typography variant="body2" color="#333333" component="p">
            – Lugar de estar.<br/>
            – Cocina integrada y equipada. Anafe. Heladera.<br/>
            – Dormitorio Principal muy cómodo: Somier, ventilador; Aire acondicionado opcional.
            </Typography>
          </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ paddingTop:'0px',paddingBottom:'0px' }}>
          <Typography variant="body2" color="#333333" component="p">
          – Lugar de descanso en entrepiso con 4 camas individuales. Ventilador.<br/>
          – Baño con zona de ducha revestida integramente en piedra.<br/>
          – Modo de Calefacción: Salamandra.<br/>
          – Ventanas protegidas con tela mosquitera.<br/>
          – Asador.<br/>
          – Cochera.<br/>
          – Excelente vista.<br/>
          – Opcional: aire acondicionado.<br/>
          </Typography>
          </CardContent>
          </Collapse>
          <CardActions disableSpacing>
          <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        </CardActions>
          <ModalGateway>
        {viewerIsOpen ? (
             <Modal onClose={closeLightbox}>
             <Carousel1
               currentIndex={currentImage}
               views={photos.map(x => ({
                 ...x,
                 srcset: x.srcSet,
                 caption: x.title
               }))}
             />
           </Modal>
        ) : null}
      </ModalGateway>
      </Card>
    );
  }