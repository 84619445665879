
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function IndexHeader() {
  return (
    <>
      <div
      id="header"
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/casas-campo/portada.webp").default + ")",
        }}
      >
        <div class="filter"/>
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Un paraíso de cabañas en San Marcos Sierras</h1>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
