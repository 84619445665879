
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionLogin() {
  return (
    <>
      <div
        className="section"
        id="como-encontrarnos"
        style={{paddingBottom: '0',backgroundColor: '#F2F2F2'}}
      > 
      <Container>
        <Row class="contact-mobile">
          <Col md="6">
          <h2 className="title" style={{marginBottom: '24px', Color: 'white'}}>Como Encontrarnos</h2>
          <img alt="Ubicacion" src={require("assets/img/icons/location.svg").default} height="30" width="30" style={{marginBottom: '15px'}}/>
          <h5 className="info-contacto" style={{marginBottom: '40px',fontWeight: '700', color:"#333333"}}>
            Av. Cacique Tulian 1881, San Marcos Sierras, Córdoba, Argentina
          </h5>
          <img alt="Telefono" src={require("assets/img/icons/phone.svg").default} height="30" width="30" style={{marginBottom: '15px'}}/> 
          <Row>
          <Col xs="6">
          <a href="tel:+54 9 351 155201394" target="_blank" rel="noreferrer" style={{ color:"#333333" }}>
          <h5 className="info-contacto" style={{marginBottom: '40px',fontWeight: '700',textAlign: 'left'}}>
          +54 9 3515201394</h5></a>
          </Col>
          </Row>
          <img alt="Email" src={require("assets/img/icons/mail.svg").default} height="30" width="30" style={{marginBottom: '15px'}}/>  
          <a href="mailto:complejocasasdecampo@hotmail.com" target="_blank" rel="noreferrer" style={{ color:"#333333" }}>
          <h5 className="info-contacto" style={{marginBottom: '40px',fontWeight: '700',textAlign: 'left'}}>            
          complejocasasdecampo@hotmail.com</h5></a> 
          <img alt="Facebook" src={require("assets/img/icons/facebook.svg").default} height="30" width="30" style={{marginBottom: '15px'}}/> 
          <a href="https://www.facebook.com/complejocasas.decampo" target="_blank" rel="noreferrer" style={{ color:"#333333" }}>
          <h5 className="info-contacto" style={{marginBottom: '40px',fontWeight: '700',textAlign: 'left'}}> 
          Complejo Casas De Campo
          </h5></a>
          <img alt="Instagram" src={require("assets/img/icons/ig.svg").default} height="30" width="30" style={{marginBottom: '15px'}}/> 
          <a href="https://www.instagram.com/cabaniascasasdecampo/" target="_blank" rel="noreferrer" style={{ color:"#333333" }}>
          <h5 className="info-contacto" style={{marginBottom: '40px',fontWeight: '700',textAlign: 'left',paddingBottom: '28px'}}> 
          @cabaniascasasdecampo
          </h5></a>
          </Col>
          <Col md="6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3428.1725942892117!2d-64.6617717!3d-30.769735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942debb72b5fb2f9%3A0xa1e7957190079148!2sComplejo%20Casas%20de%20Campo!5e0!3m2!1ses!2sar!4v1624298110685!5m2!1ses!2sar" width="100%" height="90%" style={{border:0,marginTop:'20px'}} allowfullscreen="" tabindex="0"></iframe>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
}

export default SectionLogin;
