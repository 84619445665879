import PropTypes from 'prop-types';
import React from 'react';
import Gallery from 'react-grid-gallery';

function shuffleArray (array) {
    for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
    }
    return array;
}

function setRowHeight(){
    var varRowHeight = 180;
    if (window.screen.width > 1024) {
        varRowHeight = 350;
    }
    console.log('width:',window.screen.width,varRowHeight, 'hola');
    return varRowHeight;
}

export default class galleryComponent extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            images: this.props.images
        };
    }

    render () {
        return (
                <div style={{
                    display: "block",
                    minHeight: "1px",
                    width: "100%",
                    overflow: "auto"}}
                    className="section">
                <Gallery images={this.state.images}
                         enableImageSelection={false}
                         rowHeight={setRowHeight()}/>
                </div>
                
        );
    }
}

galleryComponent.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            thumbnail: PropTypes.string.isRequired,
            srcset: PropTypes.array,
            caption: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element
            ]),
            thumbnailWidth: PropTypes.number.isRequired,
            thumbnailHeight: PropTypes.number.isRequired,
            isSelected: PropTypes.bool
        })
    ).isRequired
};

galleryComponent.defaultProps = {
    images: shuffleArray([
        {
            src: `${require('assets/img/Gallery/1.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/1.webp').default}`,
            thumbnailWidth: 240,
            thumbnailHeight: 200,
        },
        {
            src: `${require('assets/img/Gallery/2.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/2.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 190,
        },
        {
            src: `${require('assets/img/Gallery/3.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/3.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 200,
        },
        {
            src: `${require('assets/img/Gallery/4.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/4.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 213,
        },
        {
            src: `${require('assets/img/Gallery/5.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/5.webp').default}`,
            thumbnailWidth: 300,
            thumbnailHeight: 320,
        },
        {
            src: `${require('assets/img/Gallery/6.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/6.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 200,
        },
        {
            src: `${require('assets/img/Gallery/7.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/7.webp').default}`,
            thumbnailWidth: 313,
            thumbnailHeight: 250,
        },
        {
            src: `${require('assets/img/Gallery/9.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/9.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 213,
        },
        {
            src: `${require('assets/img/Gallery/10.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/10.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 213,
        },
        {
            src: `${require('assets/img/Gallery/11.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/11.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 213,
        },
        {
            src: `${require('assets/img/Gallery/12.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/12.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 213,
        },
        {
            src: `${require('assets/img/Gallery/13.webp').default}`,
            thumbnail: `${require('assets/img/Gallery/13.webp').default}`,
            thumbnailWidth: 320,
            thumbnailHeight: 213,
        }
    ])
};




